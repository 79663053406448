import React, { useState } from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import { graphql, useStaticQuery } from "gatsby"
import NewsCard from "components/news-card"
import Button from "components/button"

// Icons
import IconCaretDown from "assets/icons/icon-caret-down.inline.svg"

const StyledNewsList = styled.section`
  padding: 64px 0;

  ${breakpoint.medium`
    padding: 136px 0;
  `}

  .news-wrapper {
    margin: -40px 0;

    ${breakpoint.medium`
      margin: -64px 0;
    `}

    .news {
      &:last-child {
        border: 0;
      }
    }
  }

  .news__load-more {
    svg {
      width: 8px;
      height: 4px;
    }
  }
`

const NewsList = () => {
  const [postsToShow, setPostsToShow] = useState(7)

  const {
    newsList: { nodes: newsList },
  } = useStaticQuery(graphql`
    query {
      newsList: allContentfulNews(sort: { order: DESC, fields: publishDate }) {
        nodes {
          ...NewsCard
        }
      }
    }
  `)

  return (
    <StyledNewsList id="newsList" className="bg--silver__pale">
      <Container>
        <div className="news-wrapper">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8">
              {newsList.slice(0, postsToShow).map((news) => (
                <NewsCard className="news" {...news} />
              ))}
            </div>
          </div>
        </div>

        {postsToShow < newsList.length && (
          <div className="news__load-more mt-5 text-center">
            <Button
              type="button"
              level="tertiary"
              className="bg--silver__extra_pale"
              onClick={() => setPostsToShow(postsToShow + 7)}
            >
              Load more
              <IconCaretDown className="ms-2" />
            </Button>
          </div>
        )}
      </Container>
    </StyledNewsList>
  )
}

export default NewsList
