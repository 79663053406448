import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

// Sections
import Hero from "sections/news/hero"
import NewsList from "sections/news/news-list"

const News = () => (
  <Layout showNewsletter={false}>
    <SEO title="News | Arena Analytics" />
    <Hero />
    <NewsList />
  </Layout>
)

export default News
