import React from "react"

// Libraries
import { graphql, useStaticQuery } from "gatsby"

// Components
import HeroWithGradient from "components/hero-with-gradient"

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "news/hero-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1440, quality: 100)
        }
      }
    }
  `)

  return <HeroWithGradient image={image} title="In the News" link="#newsList" />
}

export default Hero
