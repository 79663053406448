import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Icons
import IconArrow from "assets/icons/icon-arrow-right--small.inline.svg"

const StyledNewsCard = styled.div`
  padding: 40px 0;
  border-bottom: 1px solid ${colors.silver__primary};

  ${breakpoint.medium`
    display: flex;
    padding: 64px 0;
  `}

  .card__logo {
    flex-shrink: 0;

    .logo {
      width: 172px;
      height: 88px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 14px 32px;
      border-radius: 200px;
      background-color: ${colors.white};
      text-align: center;

      ${breakpoint.medium`
        margin-right: 32px;
      `}
    }
  }
`

const NewsCard = (props) => {
  const {
    className,
    title,
    slug,
    externalUrl,
    outletLogo,
    publishDate,
    excerpt,
  } = props

  return (
    <StyledNewsCard className={className}>
      <div className="card__logo mb-4 mb-md-0">
        <div className="logo">
          <GatsbyImage image={getImage(outletLogo)} alt="" />
        </div>
      </div>

      <div className="card__content">
        <p className="mb-2 font-weight--700">{publishDate}</p>

        {externalUrl ? (
          <a
            href={externalUrl}
            className="h1 mb-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
        ) : (
          <Link to={`/news/${slug}`} className="h1 mb-2">
            {title}
          </Link>
        )}

        {excerpt && <p className="mb-2 text--large">{excerpt.excerpt}</p>}

        {externalUrl ? (
          <a
            href={externalUrl}
            className="d-inline-flex align-items-center color--turquoise__primary color-hover--navy__dark font-weight--700"
            target="_blank"
            rel="noopener noreferrer"
          >
            More <IconArrow className="ms-2" />
          </a>
        ) : (
          <Link
            to={`/news/${slug}`}
            className="d-inline-flex align-items-center color--turquoise__primary color-hover--navy__dark font-weight--700"
          >
            More <IconArrow className="ms-2" />
          </Link>
        )}
      </div>
    </StyledNewsCard>
  )
}

NewsCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  externalUrl: PropTypes.string.isRequired,
  outletLogo: PropTypes.shape({}).isRequired,
  publishDate: PropTypes.string.isRequired,
  excerpt: PropTypes.shape({
    excerpt: PropTypes.string.isRequired,
  }).isRequired,
}

NewsCard.defaultProps = {
  className: null,
}

export default NewsCard
